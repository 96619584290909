import {
	Alert,
	Badge,
	Box,
	Chip,
	CircularProgress,
	Stack,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { IDriverPackages } from '../../DTOs';
import Layout from '../../components/Layout';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import DeliveriesMap from './DeliveriesMap';
import DeliveryCard from './DeliveryCard';
import Searchbar from './Searchbar';

interface IDeliveryNeighborhood {
	neighborhood: string;
	city: string;
	quantity: number;
	hasBlockDelivery: boolean;
}

const Deliveries = () => {
	const { user } = useAuth();
	const { enqueueSnackbar } = useSnackbar();

	const { isLoading, data } = useQuery<IDriverPackages>(
		[`/drivers/${user.id}/deliveries`],
		async () => {
			const { data } = await api.get<IDriverPackages>(
				`/drivers/${user.id}/deliveries`
			);

			return data;
		},
		{
			onError: (error) => {
				console.log('error', error);
				enqueueSnackbar('Não foi possível receber os pedidos de entrega', {
					variant: 'error',
				});
			},
			networkMode: 'offlineFirst',
		}
	);

	const packages = useMemo(() => {
		try {
			if (!data) return [];

			if (data && !data.packages) return [];

			return data.packages;
		} catch (error) {
			return [];
		}
	}, [data]);

	const packagesWithWarnings = packages.filter(
		(pkg) => pkg.isBlockDelivery === true
	);

	const neighborhoods = useMemo(
		() =>
			packages.reduce((acc, curr) => {
				const existent = acc.find(
					(item) => item.neighborhood === curr.neighborhood
				);

				if (existent) {
					const index = acc.indexOf(existent);

					if (curr.isBlockDelivery) {
						acc.splice(index, 1, {
							...existent,
							quantity: existent.quantity + 1,
							hasBlockDelivery: true,
						});
					} else {
						acc.splice(index, 1, {
							...existent,
							quantity: existent.quantity + 1,
							hasBlockDelivery: existent.hasBlockDelivery || false,
						});
					}
				} else {
					const { neighborhood, city } = curr;

					acc.push({
						neighborhood,
						city,
						quantity: 1,
						hasBlockDelivery: curr.isBlockDelivery,
					});
				}

				return acc;
			}, [] as IDeliveryNeighborhood[]),
		[packages]
	);

	if (isLoading) {
		return (
			<Layout>
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						height: '100%',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress size="4rem" />
				</Box>
			</Layout>
		);
	}

	return (
		<Layout>
			<Stack
				width="100%"
				maxWidth="400px"
				direction="column"
				justifyContent="flex-start"
				alignItems="center"
				padding={2}
				spacing={2}
			>
				<DeliveriesMap deliveries={packages} />

				<Searchbar packages={packages} />

				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: 4,
					}}
				>
					<Badge
						badgeContent={packages.length || '0'}
						max={packages.length}
						color="primary"
					>
						<Chip label="Total de pacotes" />
					</Badge>
					<Badge
						badgeContent={packagesWithWarnings.length || '0'}
						max={packagesWithWarnings.length}
						color="primary"
					>
						<Chip label="Pacotes com avisos" />
					</Badge>
				</Box>

				<Stack
					width="100%"
					direction="column"
					justifyContent="flex-start"
					alignItems="center"
					spacing={2}
				>
					{neighborhoods.length === 0 ? (
						<Alert sx={{ width: '100%' }} severity="success">
							Nenhum pacote disponível no momento
						</Alert>
					) : (
						neighborhoods.map((item) => (
							<DeliveryCard key={item.neighborhood} data={item} />
						))
					)}
				</Stack>
			</Stack>
		</Layout>
	);
};

export default Deliveries;
